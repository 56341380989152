<template>
   <b-card class="blog-edit-wrapper">
    <h4><mark>백서</mark></h4>
    <!-- form -->
      <b-form class="mt-2" @submit.prevent="">
        <b-row>
        <b-col cols="12">
          <quill-editor
            id="blog-content"
            :options="snowOption"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
          >
            Save Changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Cancel
          </b-button>
        </b-col>
        </b-row>
      </b-form>
    </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
export default {
  data(){
    return {
      snowOption: {
        theme: 'snow',
        row:10,
        },
    }
  },
  components: {
  
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    quillEditor
    
  },

}
</script>

<style>

</style>