<template>
  <b-card class="blog-edit-wrapper">
    <h4><mark>기본정보</mark></h4>
    <!-- form -->
    <b-form class="mt-2" @submit.prevent="update_doc">
      <b-row>
        <b-col xl=12 lg=12 md="12" sm="12">
          <b-form-group label="Status" label-for="Status" class="mb-2">
            <v-select
              id="Status"
              v-model="nftEdit.Status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOption"
              required
            />
          </b-form-group>
        </b-col>
        <b-col xl="12" lg="12" md="12" sm="12"  v-if="nftEdit.Status=='Published'">
          <b-form-group label="Opensea" label-for="Opensea" class="mb-2">
            <b-form-input id="Opensea" v-model="nftEdit.Opensea" placeholder="오픈시"/>
          </b-form-group>
        </b-col>
        <!-- <b-col xl=6 lg=6 md="12" sm="12">
          <b-form-group label="프젝명(한글)" label-for="NameKor" class="mb-2">
            <b-form-input id="NameKor" v-model="nftEdit.NameKor" placeholder="프젝명(한글)" required/>
          </b-form-group>
        </b-col> -->

        <b-col xl=6 lg=6 md="12" sm="12">
          <b-form-group label="Name" label-for="NameEn" class="mb-2">
            <b-form-input id="NameEn" v-model="nftEdit.NameEn" placeholder="Project name" required/>
          </b-form-group>
        </b-col>
   
        <b-col xl=6 lg=6 md="12" sm="12">
          <b-form-group label="chain" label-for="Chain" class="mb-2">
            <v-select
              id="Chain"
              v-model="nftEdit.Chain"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="chainOption"
              required
              
            />
          </b-form-group>
        </b-col>
       
         <b-col xl="6" lg="6" md="12" sm="12">
          <b-form-group label="Web (★full link)" label-for="Web" class="mb-2">
            <b-form-input id="Web" v-model="nftEdit.Web" placeholder="https://xxx.com"/>
          </b-form-group>
        </b-col>

      
        <b-col xl="4" lg="4" md="12" sm="12">
          <b-form-group label="Twitter" label-for="Twitter" class="mb-2" >
            <b-form-input id="Twitter" v-model="nftEdit.Twitter" placeholder="Twitter"/>
          </b-form-group>
        </b-col>
        <b-col xl="4" lg="4" md="12" sm="12">
          <b-form-group label="Discord (★full link)" label-for="Discord" class="mb-2">
            <b-form-input id="Discord" v-model="nftEdit.Discord" placeholder="Discord"/>
          </b-form-group>
        </b-col>
        <b-col xl="4" lg="4" md="12" sm="12">
          <b-form-group label="Telegram" label-for="Telegram" class="mb-2">
            <b-form-input id="Telegram" v-model="nftEdit.Telegram" placeholder="Telegram"/>
          </b-form-group>
        </b-col>
       
       <b-col xl="6" lg="6" md="12" sm="12">
          <b-form-group label="roadmap (★full link)" label-for="roadmap" class="mb-2">
            <b-form-input id="roadmap" v-model="nftEditInfo.roadmap" placeholder="RoadMapLink https://xxx.com" />
          </b-form-group>
        </b-col>
        <b-col xl="6" lg="6" md="12" sm="12">
          <b-form-group label="whtiepaper (★full link)" label-for="Paper" class="mb-2">
            <b-form-input id="whtiepaper" v-model="nftEditInfo.whitepaper" placeholder="WhitePaperLink  https://xxx.com" />
          </b-form-group>
        </b-col>
        <b-col xl="12" lg="12" md="12" sm="12">
          <label for="textarea-default">your opinion</label>
          <b-form-textarea class="mb-2"
            id="textarea-default"
            placeholder="Textarea"
            rows="3"
            v-model="nftEditInfo.opinion"
          />
        </b-col>
        <!-- <a :href="`https://opensea.com/collection/${nftEdit.Opensea}`" target="_blank">{{nftEdit.Opensea}}</a>
        <b-col xl="6" lg="6" md="12" sm="12">
          <b-form-group label="thum" label-for="Paper" class="mb-2">
            <b-form-input id="thum" v-model="nftEdit.Thumbnail" placeholder="thumb  https://xxx.com" />
          </b-form-group>
        </b-col> -->


        <!-- <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Featured Image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  :src="file"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted">Required image resolution 800x400, image size 10mb.</small>
                <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    {{ file ? file.name : 'banner.jpg' }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    v-model="file"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer"
                    @change="inputChaged=true;"
                    
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col> -->
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
          >
            Save Changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="danger"
            @click="delete_doc"
          >
            삭제하기
          </b-button>
        </b-col>
      </b-row>
    </b-form>
     
    <!--/ form -->
  </b-card>
  
</template>

<script>
import {
  BCard, BMedia,BFormTextarea, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

import { ref } from '@vue/composition-api'
import { serverTimestamp,doc,updateDoc,deleteDoc } from "firebase/firestore";
import { uploadBytes,ref as imgRef,getDownloadURL } from 'firebase/storage'

import { db,storage } from "../firebase"


import { uuid } from 'vue-uuid'
import router from '@/router'
import store from '@/store/index'

export default {
  //normal
  components: {
  
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    vSelect,
    quillEditor,
   
  },
  directives: {
    Ripple,
  },
  data() {
    return {
        updateDocumentType : 'basic',
        statusOption: ['Published', 'Upcomming'],
        chainOption : ['ETH','SOLANA','KLAY','TERRA'],
        inputChaged : false,
        
    }
  },
  
  setup() {  
    //썸네일 사진관련
    // const refInputEl = ref(null)
    // const refPreviewEl = ref(null)
    // const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })
    
    // let file = ref(null)
    // let fullpath = ref(null)
    //form 관련
    const nftEdit = ref({
        Id : store.state.nfts.nftDetaildata.Id,
        NameKor : store.state.nfts.nftDetaildata.NameKor,
        NameEn : store.state.nfts.nftDetaildata.NameEn,
        Chain : store.state.nfts.nftDetaildata.Chain,
        Status : store.state.nfts.nftDetaildata.Status,
        Twitter : store.state.nfts.nftDetaildata.Twitter,
        Discord : store.state.nfts.nftDetaildata.Discord,
        Telegram : store.state.nfts.nftDetaildata.Telegram,
        Web : store.state.nfts.nftDetaildata.Web,
        Opensea : store.state.nfts.nftDetaildata.Opensea,
        Level : store.state.nfts.nftDetaildata.Level,
        Thumbnail : store.state.nfts.nftDetaildata.Thumbnail,
    })

    let nftEditInfo = ref({
      whitepaper : store.state.nfts.nftDetaildata.info[0].whitepaper,
      roadmap : store.state.nfts.nftDetaildata.info[0].roadmap,
      opinion : store.state.nfts.nftDetaildata.info[0].opinion,
    })
    
   
    //사진관련
    // if 아무것도 없을경우
    // elif 무언가 잇을경우
    // if(file.value == null){
    //   async function getThumbnail(){
    //     await getDownloadURL(imgRef(storage,nftEdit.value.Thumbnail))
    //     .then((url)=>{
    //       file.value = url
    //     }) 
    //   }
    //   getThumbnail()
    // }
    async function update_doc(){
      nftEdit.value.CreateDate = serverTimestamp() //현재시간
      try{
          // 스토리지에 넣기
          // if(this.inputChaged == true){
          //   const folder = 'thumbnail'
          //   const filename = uuid.v1()
          //   const fileNameLength = file.value.name.length;
          //   const lastDotSpot = file.value.name.lastIndexOf('.');
          //   const extention = file.value.name.substring(lastDotSpot+1,fileNameLength).toLowerCase()
          //   fullpath = folder+'/'+filename+'.'+extention
          //   nftEdit.value.Thumbnail = fullpath
          //   const thumnailRef = imgRef(storage, fullpath);
          //   uploadBytes(thumnailRef, file.value).then((snapshot) => {
          //       console.log('성공')
          //   });
          // }
          //db에 넣기
          const nftSnap = doc(db, "nfts",nftEdit.value.Id)
          const nftInfoSnap = doc(db, "nfts",nftEdit.value.Id,'info',store.state.nfts.nftDetaildata.info[0].id)
     
          await updateDoc(nftSnap,nftEdit.value)
          await updateDoc(nftInfoSnap,nftEditInfo.value)

          alert('변경완료')
      }catch(err){
          console.log(err)
      }
    }
    async function delete_doc(){
      if (confirm("정말 삭제하시겠습니까??") == true){
        let docRef = doc(db,"nfts",nftEdit.value.Id)
        await deleteDoc(docRef)
        let index = store.state.nfts.nftData.findIndex(data => data.id == nftEdit.value.Id)
        store.state.nfts.nftData.splice(index,1)
        router.push('/nft-list')
      }else{
        return false;
      }
    }

    return {  
      // refInputEl,
      // refPreviewEl,
      // file,
      // inputImageRenderer,
      nftEdit,
      nftEditInfo,
      update_doc,
      delete_doc
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
