<template>
  <b-card class="blog-edit-wrapper">
      <!-- {{ this.$store.state.nfts.nftDetaildata  }} -->
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mb-1" v-if="this.updateDocumentType=='all'" @click="updateDocumentType='all'">전체</b-button>
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" class="mr-1 mb-1" v-else @click="updateDocumentType='all'">전체</b-button>

    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mb-1" v-if="this.updateDocumentType=='basic'" @click="updateDocumentType='basic'">기본정보</b-button>
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" class="mr-1 mb-1" v-else @click="updateDocumentType='basic'">기본정보</b-button>

    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mb-1" v-if="this.updateDocumentType=='minting'" @click="updateDocumentType='minting'">민팅정보</b-button>
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" class="mr-1 mb-1" v-else @click="updateDocumentType='minting'">민팅정보</b-button>

     <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn float-right mr-1 mb-1" @click="">삭제하기</b-button> -->

  
    <div v-if="this.updateDocumentType=='all'">
      <NftUpdateBasic />
      <hr />
      <NftUpdateMinting />
      <hr />
   
    </div>
    <div v-else-if="this.updateDocumentType=='basic'">
      <NftUpdateBasic />
    </div>
    <div v-else-if="this.updateDocumentType=='minting'">
      <NftUpdateMinting />
    </div>
    <!-- <div v-else-if="this.updateDocumentType=='book'">
      <NftUpdateBook />
    </div>
    <div v-else-if="this.updateDocumentType=='map'">
      <NftUpdateMap />
    </div> -->
    <!-- <NftUpdateBasic v-if="this.updateDocumentType=='basic'"/> -->
  </b-card>
  
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BCard,BButton } from 'bootstrap-vue'
import store from '@/store/index'
import NftUpdateBasic from '../components/NftUpdateBasic'
import NftUpdateMinting from '../components/NftUpdateMinting'
import NftUpdateBook from '../components/NftUpdateBook'
import NftUpdateMap from '../components/NftUpdateMap'
export default {
  //normal
  beforeCreate(){
      store.state.nfts.nftDetaildata = null;//첨에 안보이게 지워도됨
      let id = this.$route.params.id
      store.dispatch('nfts/getNftDetailData',id)
   
  },
  components: {
    NftUpdateBasic,
    NftUpdateMinting,
    NftUpdateBook,
    NftUpdateMap,
    BCard,
    BButton,
    Ripple
  },
  data() {
    return {
        updateDocumentType : 'basic',
    }
  },

}
</script>

