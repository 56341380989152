<template>
<b-card class="blog-edit-wrapper">
    <h4><mark>민팅일정</mark></h4>
    <!-- {{this.$store.state.nfts.nftDetaildata.mintings}} -->
    <table
    role="table"
    aria-busy="false"
    aria-colcount="4"
    class="table b-table">
    <thead role="rowgroup" class="">
        <tr role="row" class="">
            <th role="columnheader" scope="col" aria-colindex="1" class="">
                <div>민팅종류</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="2" class="">
                <div>회차</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="3" class="">
                <div>수량</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="4" class="">
                <div>날짜</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="5" class="">
                <div></div>
            </th>
          
        </tr>
    </thead>
    <tbody role="rowgroup">
        <tr role="row" class="" v-for="list,index in this.$store.state.nfts.nftDetaildata.mintings" :key="index">
            <td aria-colindex="1" role="cell" class="">{{ list.type }}</td>
            <td aria-colindex="2" role="cell" class="">{{ list.num }}</td>
            <td aria-colindex="3" role="cell" class="">{{ list.amount }}</td>
            <td aria-colindex="4" role="cell" class="">
              {{ list.date }}
              </td>
            <td aria-colindex="5" role="cell" class="">
              <a @click="deleteMinting(list.id,index)">
              <b-badge variant="danger" >
                삭제
              </b-badge>
              </a>
            </td>
        </tr>
    </tbody>
</table>

    <b-form class="mt-2" @submit.prevent="addMinting">
      <b-row>
        <b-col xl=3 lg=3 md="4" sm="12">
          <b-form-group label="민팅종류" label-for="type" class="mb-2">
            <v-select
              id="type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="type"
              required
              v-model="mintingData.type"   
            />
          </b-form-group>
        </b-col>

        <b-col xl=3 lg=3 md="4" sm="12">
          <b-form-group label="회차" label-for="num" class="mb-2">
            <v-select
              id="num"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="num"
              required  
              v-model="mintingData.num"               
            />
          </b-form-group>
        </b-col>        

        <b-col xl=3 lg=3 md=4 sm=12>
          <b-form-group label="판매갯수" label-for="count" class="mb-2">
            <b-form-input id="count"  placeholder="판매갯수" type="number" required v-model="mintingData.amount"/>
          </b-form-group>
        </b-col>

        <b-col xl=3 lg=3 md=4 sm=12>
  
             <b-form-group>
          <h5>Date & TIme</h5>
          <flat-pickr
            v-model="mintingData.date"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
           placeholder="UTC 기준"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit">
            추가하기
          </b-button>
          
  </b-form>
</b-card>
</template>

<script>
import { BBadge,BTable,BCard,BMedia,BAvatar,BCardText,BMediaAside,BMediaBody,BForm,BLink,BImg,BRow,BCol,BButton,BFormGroup,BFormInput,BFormFile } from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store/index'
import {ref,reactive} from '@vue/composition-api'
import {db} from '../firebase'
import {addDoc,collection,deleteDoc,doc} from 'firebase/firestore'
export default {
  components: {BBadge,BTable,BCard,BMedia,BAvatar,BCardText,BMediaAside,BMediaBody,BForm,BLink,BImg,BRow,BCol,BButton,BFormGroup,BFormInput,BFormFile,
vSelect,BCardCode,flatPickr
  },
  data() {
    return {    
      type : ['DutchAuction','PreSale','Public','Preminting'],
      num : [1,2,3,4,5,6,7,8,9],
      typeMatch : {'더치옥션':'DutchAuction','프리세일':'PreSale' ,'퍼블릭':'Public','프리민팅':'Preminting'},
      typeReMatch : {'DutchAuction':'더치옥션','PreSale':'프리세일','Public':'퍼블릭','Preminting':'프리민팅'},
      
    }
  },
  methods:{
    timeConverter(UNIX_timestamp){
      var a = new Date(UNIX_timestamp * 1000);
      var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      var year = a.getFullYear();
      var month = a.getMonth()
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
    
      var time = year +"-"+ month+"-"+date+" "+ hour + ':' + min;
      return time;
    }
  },
  setup(){
    let mintingData = reactive({
        num : 1,
        amount : 0,
        type : 'DutchAction',
        date : null,
      })
    async function addMinting(){
      let id = store.state.nfts.nftDetaildata.Id
      const refId = await addDoc(collection(db, "nfts",id,'minting'),mintingData);
      store.state.nfts.nftDetaildata.mintings.push(
        {num:mintingData.num,amount:mintingData.amount,type:mintingData.type,date:mintingData.date,id:refId.id}
      )
    }
    async function deleteMinting(mintingId,index){
       if (confirm("정말 삭제하시겠습니까??") == true){    //확인
          let id = store.state.nfts.nftDetaildata.Id
          await deleteDoc(doc(db, "nfts",id,'minting',mintingId));
          store.state.nfts.nftDetaildata.mintings.splice(index,1)
        }else{   //취소
            return false;
        }

      
    }
   
    return {
      mintingData,
      addMinting,
      deleteMinting,
     
    }
  }
 
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>